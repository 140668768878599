import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Img from "gatsby-image";
import Masonry from "react-masonry-component";
import Layout from "../components/layout";
import "../styles/testimonials.sass";
import Footer from "../components/footer";
import "../styles/title-img.sass";

const Testimonials = ({ data }) => (
  <Layout>
    <div className="title-container">
    <HelmetDatoCms seo={data.datoCmsTestimonialsPage.seoMetaTags} />
      <div className="title-image">
        <div className="title">
          <h1>{data.datoCmsTestimonialsPage.title}</h1>
        </div>
        <Img
          fluid={data.datoCmsTestimonialsPage.testimonialsTitleImage.fluid}
        />
      </div>
    </div>
    <Masonry className="showcase">
      {data.allDatoCmsTestimonial.edges.map(({ node: testimonial }) => (
        <div key={testimonial.id} className="showcase__item">
          <figure className="card">
            <figcaption className="card__caption">
              <h6 className="card__title">{testimonial.name}, </h6>
              <p className="card__location">{testimonial.location}</p>
              <div 
              className="card__description">
              <p dangerouslySetInnerHTML={{
            __html: testimonial.testimonialNode.childMarkdownRemark.html,
          }}/>
          </div>
            </figcaption>
          </figure>
        </div>
      ))}
    </Masonry>
    <Footer />
  </Layout>
);

export default Testimonials;

export const query = graphql`
  query TestimonialsQuery {
    datoCmsTestimonialsPage {
      title
      testimonialsTitleImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    
    allDatoCmsTestimonial {
      edges {
        node {
          id
          testimonial
          name
          location
          testimonialNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
